import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";

import styles from "./blog.module.scss";

export const query = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug }, language: { eq: $language } }
    ) {
      frontmatter {
        title
        date
      }
      html
    }
  }
`;

const Blog = ({ data }) => {
  return (
    <Layout>
      <Head title={data.markdownRemark.frontmatter.title} />

      <Heading>
        <h1>{data.markdownRemark.frontmatter.title}</h1>
      </Heading>

      <div className={styles.mainContainer}>
        <p>{data.markdownRemark.frontmatter.date}</p>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
      </div>
    </Layout>
  );
};

export default React.memo(Blog);
